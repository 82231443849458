<template>
    <div>
        <div class="row center py-100">
            <div class="wrapper">
                <lottie-player autoplay mode="normal" speed="0.5" :src="json" style="width: 70px" />
            </div>
        </div>
        <div class="row center">
            <h1>Payment declined</h1>
        </div>

        <section class="w600 mx-auto mt-20 center">
            <router-link to="/licenses" class="green">
                <s-btn class="green">close</s-btn>
            </router-link>
        </section>
    </div>
</template>

<script>
import "@lottiefiles/lottie-player";

export default {
    data() {
        return {
            json: "https://assets8.lottiefiles.com/private_files/lf30_mlsj6yqm.json"
        }
    },
    mounted() {

        var animation = document.getElementById("successAnimation");
    },
};
</script>

<style scoped lang='less'>
.checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #409875;
    fill: none;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #fff;
    stroke-miterlimit: 10;
    margin: 10% auto;
    box-shadow: inset 0px 0px 0px #409875;
    animation: fill 0.4s ease-in-out 0.4s forwards,
        scale 0.3s ease-in-out 0.9s both;
}

.checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

.wrapper {
    transform: scale(3);
}

@keyframes stroke {
    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes scale {

    0%,
    100% {
        transform: none;
    }

    50% {
        transform: scale3d(1.1, 1.1, 1);
    }
}

@keyframes fill {
    100% {
        box-shadow: inset 0px 0px 0px 30px #409875;
    }
}
</style>